import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import Text from "../components/atoms/Text/Text";
import GridContainer from "../components/grid/GridContainer";
import HeroNavigation from "../components/global/HeroNavigation/HeroNavigation";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import NineColumnsCell from "../components/grid/NineColumnsCell";
import ThreeColumnsCell from "../components/grid/ThreeColumnsCell";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";

type propType = {
	data: any
	classes: any,
	context: any
}

const IndexPage = ({data}: propType) => {
	const {t} = useTranslation();

  return (
  	<>
		<Seo title={t("home:seo.title")} description={t("home:seo.description")} />
			<HeroNavigation />


			<ColoredBox className="margin-top-0--small">
				<FullWidthContainer>
					<h1>
						<Text text="home:sectionIntroduction.headline" />
					</h1>
					<p>
						<Text text="home:sectionIntroduction.copy1" />
					</p>
				</FullWidthContainer>
				<GridContainer>
					<ThreeColumnsCell expandForMobile={true}>
						<GatsbyImage
							image={getImage(data.enzymsImage)!}
							loading="lazy"
							alt={t("home:sectionIntroduction.imageAltText")}
						/>
					</ThreeColumnsCell>
					<NineColumnsCell expandForMobile={true}>
						<p className="margin-top-0--medium-up">
							<Text text="home:sectionIntroduction.copy2" />
						</p>
					</NineColumnsCell>
				</GridContainer>
				<FullWidthContainer>
					<p>
						<Text text="home:sectionIntroduction.copy4" />
					</p>
					<p>
						<Text text="home:sectionIntroduction.copy5" />
					</p>
				</FullWidthContainer>
			</ColoredBox>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('home:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="home:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="home:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="home:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
  )
}

export default IndexPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "home"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		enzymsImage: file(
			relativePath: { eq: "home/enzyms.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
				)
			}
		},
	}
`;
