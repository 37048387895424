// extracted by mini-css-extract-plugin
export var root = "HeroNavigation-module--root--1ihL6";
export var hero = "HeroNavigation-module--hero--3wxww";
export var imageContainer = "HeroNavigation-module--image-container--11yo2";
export var box = "HeroNavigation-module--box--2ST4I";
export var image = "HeroNavigation-module--image--2MTVK";
export var diseaseBox = "HeroNavigation-module--disease-box--2IeYe";
export var treatmentBox = "HeroNavigation-module--treatment-box--2qSw1";
export var symptomsBox = "HeroNavigation-module--symptoms-box--1NcEM";
export var diagnosisBox = "HeroNavigation-module--diagnosis-box--3gX_j";
export var centersBox = "HeroNavigation-module--centers-box--3PQN3";
export var dbsKitBox = "HeroNavigation-module--dbs-kit-box--2yjgR";