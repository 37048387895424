import React from "react";
import * as styles from './HeroNavigation.module.scss';
import {GatsbyImage, getImage, IGatsbyImageData, withArtDirection} from "gatsby-plugin-image";
import {graphql, StaticQuery} from "gatsby";
import classNames from "classnames";
import TranslatedLink from "../../util/TranslatedLink";
import {useTranslation} from "gatsby-plugin-react-i18next";
import Text from "../../atoms/Text/Text";


const HeroNavigation = () => {
    const HeroImage = (data: {heroDesktop: IGatsbyImageData, heroTablet: IGatsbyImageData}) => {
        const image = withArtDirection(getImage(data.heroDesktop)!, [
            {
                media: "(max-width: 1024px)",
                image: getImage(data.heroTablet)!,
            }
        ]);
        const {t} = useTranslation();

        return (
            <div className={styles.root}>
                <span className={styles.hero}>
                    <Text text="slogan" />
                </span>
                <div className={styles.imageContainer}>
                    <GatsbyImage className={styles.image} alt={t('heroNavigation.imageAlt')} objectFit="cover" image={image} />

                    <div className={classNames(styles.diseaseBox, styles.box)}>
                        <TranslatedLink to="/disease" title={t('heroNavigation.linktTitleDisease')}>{t('menu.disease')}</TranslatedLink>
                    </div>
                    <div className={classNames(styles.treatmentBox, styles.box)}>
                        <TranslatedLink to="/treatment" title={t('heroNavigation.linktTitleTreatment')}>{t('menu.treatment')}</TranslatedLink>
                    </div>
                    <div className={classNames(styles.symptomsBox, styles.box)}>
                        <TranslatedLink to="/symptoms" title={t('heroNavigation.linktTitleSymptoms')}>{t('menu.symptoms')}</TranslatedLink>
                    </div>
                    <div className={classNames(styles.diagnosisBox, styles.box)}>
                        <TranslatedLink to="/diagnosis" title={t('heroNavigation.linktTitleDiagnosis')}>{t('menu.diagnosis')}</TranslatedLink>
                    </div>
                    <div className={classNames(styles.centersBox, styles.box)}>
                        <TranslatedLink to="/centers" title={t('heroNavigation.linktTitleCenters')}>{t('menu.centers')}</TranslatedLink>
                    </div>
                    <div className={classNames(styles.dbsKitBox, styles.box)}>
                        <TranslatedLink to="/order" title={t('heroNavigation.linktTitleOrder')}>{t('menu.dbsKit')}</TranslatedLink>
                    </div>

                </div>
            </div>
        );
    }

    const staticQuery = <StaticQuery
        query={graphql`
            query HeadingQuery {
                heroDesktop: file(
                    relativePath: { eq: "home/HeroDesktop.jpg"}
                ) {
                    id
                    relativePath
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            formats: [AUTO, WEBP, AVIF],
                            breakpoints: [1300, 1024, 640],
                            quality: 75,
                            sizes: "(min-width: 1300px) 1292px, 100vw"
                        )
                    }
                },
                heroTablet: file(
                    relativePath: { eq: "home/HeroTablet.jpg"}
                ) {
                    id
                    relativePath
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            formats: [AUTO, WEBP, AVIF],
                            breakpoints: [1024, 1024, 620],
                            quality: 75,
                            sizes: "(min-width: 1300px) 1292px, 100vw",
                        )
                    }
                }
            }
        `}
        render={HeroImage}
    />

   return staticQuery

}

export default HeroNavigation