import React from "react";

import * as styles from "./NineColumnsCell.module.scss";
import classNames from "classnames";

type propsType = {
    children: React.ReactNode
    expandForMobile?: boolean
    expandforSmallOnly?: boolean
}

const NineColumnsCell = (props: propsType) => {

    let rootClasses = classNames(
        styles.root,
        {[styles.expandForMobile]: props.expandForMobile},
        {[styles.expandforSmallOnly]: props.expandforSmallOnly}
    );

    return (<div className={rootClasses}>
        {props.children}
    </div>);
};

export default NineColumnsCell;